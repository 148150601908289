import { fetchDelete, fetchGet, fetchPost } from './index';

import { MaintainColProps, MaintainTableProps, WordingProps } from '@/views/Program/types';
import { AutoTableCellProps, APIDataProps } from '@/types';
interface MappingProps {
  id: number;
  name: string;
  enabled: boolean;
}

interface MappingDataProps {
  mapping: MappingProps[];
}

// formula start
export interface FormulaProps {
  id?: string;
  code?: string;
  display?: string;
  tag?: string;
  operator?: string;
}
export interface FormulaGroupProps {
  precondition: FormulaProps[];
  incentive: FormulaProps[];
  vehicleLicense: FormulaProps[];
  formula: FormulaProps[];
}
export interface OverallProps {
  formula: FormulaGroupProps;
  incentiveAmount: [];
  offerTypeId: string;
  offerTypeName: string;
  precondition: [];
  vehicleLicense: [];
}
export interface PgapiProgramProps {
  programId?: string;
  programCode?: string;
  programName?: string;
  businessUnit?: any;
  eligibility?: any;
  offerType?: {
    overall?: OverallProps;
    fa?: any;
    sssc: any;
  };
  offer?: OverallProps;
  select?: any;
  customer: any;
}
// formula end
interface RebateProps {
  matched: [];
}

interface FinancingProps {
  tableNameList: [];
}
export interface DesignSettingProps {
  customer: CustomerTypeProps[];
}
export interface CustomerTypeProps {
  description: {
    customVehicleDescription: string;
    defaultDescription: string;
  };
  customVehicleDescription: string;
  defaultDescription: string;
  id: string;
  name: string;
  nameCn: string;
  requireTradeIn: boolean;
}

// 新建一个program

// budgeting
export const budgetGenerate = (id: string | string[]): Promise<PgapiProgramProps> => {
  return fetchGet('/bgapi/budget/generate/' + id);
};
// /program/{program-id}/preview
// Program 预览 PDF
export const programPreview = (id: string | string[]): Promise<PgapiProgramProps> => {
  return fetchGet(`/pgapi/program/${id}/preview`);
};
export const programTemplate = (): Promise<PgapiProgramProps> => {
  return fetchPost('/pgapi/program/data/create_program');
};
export const programProgramId = (params: {}): Promise<PgapiProgramProps> => {
  return fetchGet('/pgapi/program/data/load_program', params);
};
export const pgapiProgram = (): Promise<PgapiProgramProps> => {
  return fetchGet('/pgapi/program');
};
export const pgapiProgramPost = (data = {}, params = {}): Promise<{ programCode: string; requireNewCode: boolean }> => {
  return fetchPost('/pgapi/program/data/store_program', data, params);
};
export const pgapiProgramPra = (id: string | string[]): Promise<PgapiProgramProps> => {
  return fetchGet('/pgapi/program/' + id);
};

export const searchHeaderProgram = (params = {}): Promise<string[]> => {
  return fetchPost('/pgapi/program/design/search_header_program', params);
};

// 获取限号城市
// export const getRestrictedData = () => {
//     return fetchGet('/mdapi/restricted-cities')
// }
export const getRestrictedData = (params = {}) => {
  return fetchGet('/pgapi/program/local_registration/rule', params);
};
// 获取 Overall OfferGroup
export const overallOfferGroup = (params = {}): Promise<{ offerGroup: MaintainColProps }> => {
  return fetchGet('/pgapi/offer_type/overall/offer_group', params);
};
// 接受 Spread JS Excel multipart file
export const sjsExcel = (params = {}): Promise<string> => {
  return fetchPost('/pgapi/program/setting/sjs/excel', params);
};
export const settingSjsExcel = (params = {}): Promise<string> => {
  return fetchGet('/pgapi/program/setting/sjs_excel', params);
};

// 获取 Overall OfferGroup 新接口
export const getOfferGroup = (params = {}): Promise<{ offerGroup: MaintainColProps }> => {
  return fetchGet('/pgapi/program/design/get_offer_group', params);
};

export const pgmaddColumn = (params = {}): Promise<AutoTableCellProps> => {
  return fetchPost('/pgapi/program/design/add_colm', params);
};

export const removeColumn = (params = {}): Promise<APIDataProps> => {
  return fetchPost('/pgapi/program/design/remove_colm', params);
};

// 将 Eligibility Vehicle 拿到 incentive 中 new接口
export const designRefreshIncentive = (params = {}): Promise<APIDataProps> => {
  return fetchPost('/pgapi/program/design/refresh_incentive', params);
};

// 获得 modelName/baumuster 可选列表
export const searchCandidate = (params = {}): Promise<string[]> => {
  return fetchPost('/pgapi/offer_type/sssc/search_candidate', params);
};

// 获得 SSSC 可选列表
export const searchSsscCandidate = (params = {}): Promise<{ [key: string]: string }[]> => {
  return fetchPost('/pgapi/offer_type/sssc/search_sssc_candidate', params);
};

// 通用下拉接口
// api/pgapi/vehicle/search_vehicle
export const searchVehicle = (params = {}): Promise<string[]> => {
  return fetchPost('/pgapi/program/design/search_eligible_vehicle', params);
};
export const searchNstGroupName = (params = {}): Promise<string[]> => {
  return fetchPost('/pgapi/program/design/search_nst_group_name', params);
};
// 新增的下拉接口
export const searchRepurchaseVehicle = (params = {}): Promise<string[]> => {
  return fetchPost('/pgapi/program/design/search_repurchase_vehicle', params);
};
// 根据关联的group得到下拉
export const searchOptions = (params = {}): Promise<string[]> => {
  return fetchPost('/pgapi/program/design/search_options', params);
};

// Repair Code下拉接口
export const searchRepairCode = (params = {}): Promise<string[]> => {
  return fetchPost('/pgapi/program/design/search_repair_code', params);
};

// Dealer下拉接口
export const searchDealer = (params = {}): Promise<string[]> => {
  return fetchPost('/pgapi/program/design/search_eligible_dealer', params);
};

// 刷新并生成公式
export const refreshFormula = (params = {}): Promise<PgapiProgramProps> => {
  return fetchPost('/pgapi/program/design/refresh_formula', params);
};

// customer
//
export const getCustomerType = (): Promise<CustomerTypeProps[]> => {
  return fetchGet('/pgapi/program/setting/get_customer_type');
};

// 小图 更换接口
export const getDesignSetting = (parasm = {}): Promise<DesignSettingProps> => {
  // return fetchPost('/pgapi/program/design/get_design_setting', parasm)
  return fetchPost('/pgapi/program/design/get_customer_types', parasm);
};
export const refreshComments = (params = {}): Promise<string> => {
  return fetchPost('/pgapi/program/design/refresh_comments', params);
};

// ranking
// rogram/design/search_ranking_criteria
export const searchRankingCriteria = (params = {}): Promise<{ [key: string]: string }[]> => {
  return fetchPost('/pgapi/program/design/search_ranking_criteria', params);
};

// 获取返利信息
export const getRebate = (): Promise<RebateProps> => {
  return fetchGet('/pgapi/sale/all/rebate');
};

// 获取省份城市 限牌城市
export const getAreasDate = () => {
  return fetchGet('/pgapi/program/design/areas');
};
// 保存 限牌城市
export const saveLocalReg = (params = {}) => {
  return fetchPost('/pgapi/program/local_registration/rule', params);
};

// 获取列表Reg
export const getRegList = (params = {}) => {
  return fetchGet('/pgapi/program/local_registration/restricted_city', params);
};

// 获取special rule
export const getSpecialData = (params = {}) => {
  // return fetchGet('/pgapi/program/local_registration/special_rule', params)
  return fetchPost('/pgapi/program/local_registration/special_rule', params);
};

export const getSpecialDataEdit = (params = {}) => {
  return fetchGet('/pgapi/program/local_registration/special_rule/global', params);
};

// 本地上牌的获取
export const findLocalData = (params = {}) => {
  return fetchPost('/pgapi/program/local_registration', params);
};

export const getSpecialParametersData = () => {
  return fetchGet('/pgapi/program/local_registration/special_rule/vehicle_parameters');
};

export const getSpecialValueData = (params = {}) => {
  return fetchGet('/pgapi/program/local_registration/special_rule/vehicle_parameter_value', params);
};

export const delSpecialData = (params = {}) => {
  return fetchDelete('/pgapi/program/local_registration/special_rule', params);
};

// 保存special rule
export const saveSpacialRule = (params = {}, specialRuleWithVehicle = {}) => {
  return fetchPost('/pgapi/program/local_registration/special_rule', params, specialRuleWithVehicle);
};
// 不保存这次本地上牌的配置
export const delLocalRule = (params = {}) => {
  return fetchDelete('/pgapi/program/local_registration', params);
};

// 兼容性----------------------------------------
// Program 兼容性 - 生成
export const loadCombinability = (params = {}): Promise<PgapiProgramProps> => {
  return fetchGet('/pgapi/program/combinability/load_combinability', params);
};
// Program 兼容性 - 获取兼容性列表
export const filterCombinability = (params = {}): Promise<PgapiProgramProps> => {
  return fetchPost('/pgapi/program/combinability/filter_combinability', params);
};
// [OASIS-613] Program 兼容性 - 配置兼容性
export const storeCombinability = (params = {}): Promise<PgapiProgramProps> => {
  return fetchPost('/pgapi/program/combinability/store_combinability', params);
};

// 兼容性----------------------------------------

// 获取 Parameter
export const getParameterData = (params = {}) => {
  return fetchGet('/mdapi/parameters', params);
};

// preview wording
export const wordingPreview = (params = {}): Promise<string> => {
  return fetchGet(`/pgapi/program/wording/preview`, params);
};

// init wording
export const wordingInit = (params = {}): Promise<string> => {
  return fetchGet(`/pgapi/program/wording/initialize_wording`, { params });
};

// replace wording
// pgapi/program/wording/get_replaced_wording_pdf?programId=4a77724a-5337-4fbb-8741-9c70e7082df3&type=Internal&lang=en
export const getReplacedWordingPdf = (
  params = {}
): Promise<{
  programId: string;
  type: string;
  lang: string;
  fileName: string;
  fileUrl: string;
}> => {
  return fetchGet(`/pgapi/program/wording/get_replaced_wording_pdf`, params);
};
export const deleteReplacedWordingPdf = (params = {}): Promise<string> => {
  return fetchGet(`/pgapi/program/wording/delete_replaced_wording_pdf`, params);
};

// compare wording
export const wordingCompare = (params = {}): Promise<WordingProps> => {
  return fetchGet(`/pgapi/program/wording/compare`, params);
};
// 查询wording
export const getEditableContent = (params = {}): Promise<WordingProps> => {
  return fetchGet(`/pgapi/program/wording/get_editable_content`, params);
};
//保存wording
export const saveEditedContent = (params = {}): Promise<WordingProps> => {
  return fetchPost('/pgapi/program/wording/save_edited_content', params);
};

// Get Release batch cover
export const getReleaseBatchCovereContent = (
  params = {}
): Promise<{ batchId: string; coverCn: string; coverEn: string }> => {
  return fetchGet(`/pgapi/maintain/get_release_batch_cover`, params);
};
export const saveReleaseBatchCover = (params = {}): Promise<WordingProps> => {
  return fetchPost('/pgapi/maintain/save_release_batch_cover', params);
};

// Fa 部分
// 获取表格 显示百分比
export const getAvaiDesData = (confinParams: any, params = {}): Promise<MaintainTableProps> => {
  const { offerType, programId, bu, brand, offer, customerType } = confinParams;
  return fetchPost(
    `/pgapi/prog/setting/fa/select_available_calculator?offerType=${offerType}&programId=${programId}&bu=${bu}&brand=${brand}&offer=${offer}&customerType=${customerType}`,
    params
  );
};

// 获取Financing Design表格数据
export const getFinacDesData = (confinParams: any, params = {}): Promise<FinancingProps[]> => {
  const { offerType, programId, bu, brand, offer, customerType } = confinParams;
  return fetchPost(
    `/pgapi/prog/setting/fa/financingDesign?offerType=${offerType}&programId=${programId}&bu=${bu}&brand=${brand}&offer=${offer}&customerType=${customerType}`,
    params
  );
};

// 获取 Fl
export const getFlDesData = (confinParams: any, params = {}): Promise<any> => {
  const { offerType, programId, bu, brand, offer, customerType } = confinParams;
  return fetchPost(
    `/pgapi/prog/setting/fa/flWithBalloonDesign?offerType=${offerType}&programId=${programId}&bu=${bu}&brand=${brand}&offer=${offer}&customerType=${customerType}`,
    params
  );
};

// 获取 EQ Fl
export const getEqFlDesData = (confinParams: any, params = {}): Promise<any> => {
  const { offerType, programId, bu, brand, offer, customerType } = confinParams;
  return fetchPost(
    `/pgapi/prog/setting/fa/nevFLWithBalloonDesign?offerType=${offerType}&programId=${programId}&bu=${bu}&brand=${brand}&offer=${offer}&customerType=${customerType}`,
    params
  );
};

// 获取
export const getAgDesData = (confinParams: any, params = {}): Promise<MaintainTableProps> => {
  const { offerType, programId, bu, brand, offer, customerType } = confinParams;
  return fetchPost(
    `pgapi/prog/setting/fa/agilityDesign?offerType=${offerType}&programId=${programId}&bu=${bu}&brand=${brand}&offer=${offer}&customerType=${customerType}`,
    params
  );
};

// 回显FA信息
export const getDesignFa = (
  params = {}
): Promise<{
  financingDesignTableDTO: { [key: string]: string }[];
  flDesignTableDTO: { [key: string]: string }[];
}> => {
  return fetchGet('pgapi/prog/setting/fa/getDesignTable', params);
};

// 获取计算器勾选的key
export const getChooseCalculator = (params = {}): Promise<any> => {
  return fetchGet('pgapi/prog/setting/fa/selectChooseCalculator', params);
};

// 是否升级版本
export const findDesignDetail = (params = {}): Promise<any> => {
  return fetchGet('pgapi/prog/setting/fa/findDesignDetail', params);
};

// 升级版本
export const updatedVersion = (params = {}): Promise<any> => {
  return fetchGet('/pgapi/prog/setting/fa/progVersionUp', params);
};

// buy down fl
export const getFlBuyDownData = (confinParams: any, params = {}): Promise<any> => {
  const { offerType, startDate, endDate, bu, brand, offer, customerType } = confinParams;
  return fetchPost(
    `/pgapi/prog/setting/fa/flBuyDownBalloonDesign?offerType=${offerType}&startDate=${startDate}&endDate=${endDate}&bu=${bu}&brand=${brand}&offer=${offer}&customerType=${customerType}`,
    params
  );
};

export const getEQCBuyDownData = (confinParams: any, params = {}): Promise<any> => {
  const { offerType, startDate, endDate, bu, brand, offer, customerType } = confinParams;
  return fetchPost(
    `/pgapi/prog/setting/fa/eqcBuyDownBalloonDesign?offerType=${offerType}&startDate=${startDate}&endDate=${endDate}&bu=${bu}&brand=${brand}&offer=${offer}&customerType=${customerType}`,
    params
  );
};

export const checkBudgetPeriod = (params = {}): Promise<boolean> => {
  return fetchPost('/pgapi/program/data/check_budget_period', params);
};

export const downloadDocxPdfWording = (params = {}): Promise<any> => {
  return fetchGet('/pgapi/program/wording/download_docx_pdf_wording', params);
};

export const isWordingUploadAll = (params = {}): Promise<any> => {
  return fetchGet('/pgapi/program/wording/wording_is_upload_all', { params });
};
