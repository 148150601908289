import { Module } from 'vuex';
import { parameterSave, parameterSave2 } from "@/API/claim/claimParameter";
import { message } from "ant-design-vue";
const claim: Module<any, any> = {
    namespaced: true,
    state: {
        afcLcType: 'AFC',
        saveParamsData: {
        },
        docDocumentsData: [],
        docExtendData: [],
        extendInfoData: [],
        claimMethodData: []
    },
    mutations: {
        updateAfcLcType(state, data) {
            state.afcLcType = data;
        },
        updateSaveParamsData(state, option){
            state.saveParamsData[option.name] = option.rawData
        },
        updateDocDocumentsData(state, data) {
            state.docDocumentsData = data
        },
        updateDocExtendData(state, data) {
            state.docExtendData = data
        },
        updateExtendInfoData(state, data) {
            state.extendInfoData = data
        },
        updateClaimMethodData(state, data) {
            state.claimMethodData = data
        },
        updateClearParamsData(state) {
            state.saveParamsData = {}
        },
        updateClearDocDocumentsData(state) {
            state.docDocumentsData = []
        },
        updateClearDocExtendData(state) {
            state.docExtendData = []
        },
        updateClearExtendInfoData(state) {
            state.extendInfoData = []
        },
        updateClearClaimMethodData(state) {
            state.claimMethodData = []
        }
    },
    actions: {
        saveApi(context) {
            const params = context.state.saveParamsData
            parameterSave(params).then((res) => {
                if (res.code === "0") {
                    message.success(res.message);
                }
            }, error => {
                console.log(error)
            });
            parameterSave2(params).then((res) => {
                /*if (res.code === "0") {
                    console.log(res.message);
                }*/
            }, error => {
                console.log(error)
            });
        }
    }
}

export default claim;
