import { RouteRecordRaw } from 'vue-router';

export const salesRepotringRouters: Array<RouteRecordRaw> = [
    {
        path: '/salesreporting/salespanel',
        name: 'SalesPanel',
        component: () => import('@/views/SalesReporting/SalesPanel/index.vue'),
    },
    //03
    {
        path: '/salesreporting/claimCheckingReportOEM',
        name: 'Sales Claim Checking OEM',
        component: () =>
            import('@/views/SalesReporting/ClaimCheckingReportOEM/index.vue'),
    },
    {
        path: '/salesreporting/claimCheckingReportDealer',
        name: 'Sales Claim Checking Dealer',
        component: () =>
            import(
                '@/views/SalesReporting/ClaimCheckingReportDealer/index.vue'
            ),
    },
    {
        path: '/salesreporting/claimCheckingReportDemoCar',
        name: 'Sales Claim Checking Demo Car',
        component: () =>
            import(
                '@/views/SalesReporting/ClaimCheckingReportDemoCar/index.vue'
            ),
    },
    //11
    {
        path: '/salesreporting/vehicleLicenseTrackingReport',
        name: 'Vehicle License Tracking Report',
        component: () =>
            import(
                '@/views/SalesReporting/VehicleLicenseTrackingReport/index.vue'
            ),
    },
    {
        path: '/salesreporting/prepaymentQualifiedOEM',
        name: 'PrepaymentQualified',
        component: () =>
            import('@/views/SalesReporting/PrepaymentQualified/OEM/index.vue'),
    },
    {
        path: '/salesreporting/prepaymentQualifiedDealer',
        name: 'prepaymentQualifiedDealer',
        component: () =>
            import(
                '@/views/SalesReporting/PrepaymentQualified/Dealer/index.vue'
            ),
    },
    {
        path: '/salesreporting/payoutForm',
        name: 'PayoutForm',
        component: () => import('@/views/SalesReporting/PayoutForm/index2.vue'),
    },
    {
        path: '/salesreporting/payoutBreakdownDealer',
        name: 'DealerPayoutBreakdown',
        component: () =>
            import('@/views/SalesReporting/PayoutBreakdown/Dealer/index.vue'),
    },
    {
        path: '/salesreporting/payoutBreakdownOEM',
        name: 'OEMPayoutBreakdown',
        component: () =>
            import('@/views/SalesReporting/PayoutBreakdown/OEM/index.vue'),
    },
    {
        path: '/salesreporting/RVSubmissionOEM',
        name: 'RVSubmissionOEM',
        component: () =>
            import('@/views/SalesReporting/RVSubmission/OEM/index.vue'),
    },
    {
        path: '/salesreporting/RVSubmissionDealer',
        name: 'RVSubmissionDealer',
        component: () =>
            import('@/views/SalesReporting/RVSubmission/Dealer/index.vue'),
    },
    {
        path: '/salesreporting/saleOverviewDealer',
        name: 'SaleOverview',
        component: () =>
            import('@/views/SalesReporting/SaleOverview/index.vue'),
    },
    {
        path: '/salesreporting/failedReasonAnalysis',
        name: 'FailedReasonAnalysis',
        component: () =>
            import('@/views/SalesReporting/FailedReasonAnalysis/index.vue'),
    },
    {
        path: '/salesreporting/checkerManagement',
        name: 'CheckerManagement',
        component: () =>
            import('@/views/SalesReporting/CheckerManagement/index.vue'),
    },
    {
        path: '/salesreporting/checkingPlanActualReport',
        name: 'CheckingPlanActualReport',
        component: () =>
            import('@/views/SalesReporting/CheckingPlanActualReport/index.vue'),
    },
    {
        path: '/salesreporting/failedReasonAnalysisDealer',
        name: 'FailedReasonAnalysisDealer',
        component: () =>
            import('@/views/SalesReporting/FailedReasonAnalysis/Dealer.vue'),
    },
    //1-06
    {
        path: '/salesreporting/paymentTrackingReportOEM',
        name: 'paymentTrackingReportOEM',
        component: () =>
            import('@/views/SalesReporting/PaymentTrackingReport/index.vue'),
    },

    //报表第二期
    {
        path: '/salesreporting/salesPanelOverview',
        name: 'salesPanelOverview',
        component: () =>
            import('@/views/SalesReporting2/SalesPanelOverview/index.vue'),
    },
    {
        path: '/salesreporting/estimationReport',
        name: 'EstimationReport',
        component: () =>
            import(
                '@/views/SalesReporting2/SICheckingPayoutEstimationReport/index.vue'
            ),
    },
    {
        path: '/salesreporting/paymentTrackingReport',
        name: 'PaymentTrackingReport',
        component: () =>
            import('@/views/SalesReporting2/PaymentTrackingReport/index.vue'),
    },
    {
        path: '/salesreporting/paymentTrackingReportReleaseInput',
        name: 'PaymentTrackingReportReleaseInput',
        component: () =>
            import(
                '@/views/SalesReporting2/PaymentTrackingReport/ReleaseInput/index.vue'
            ),
    },
    //2-04
    {
        path: '/salesreporting/salesCompetationResult',
        name: 'Sales Competation Result',
        component: () =>
            import('@/views/SalesReporting/SalesCompetationResult/index.vue'),
    },
    //2-07
    {
        path: '/salesreporting/rvVsCESARSubmissionAnalystReport',
        name: 'RV vs CESAR Submission Analyst Report',
        component: () =>
            import(
                '@/views/SalesReporting/RVVsCESARSubmissionAnalystReport/index.vue'
            ),
    },
    //2-2
    {
        path: '/salesreporting/penetrationReportByProgramContent',
        name: 'Penetration Report',
        component: () =>
            import(
                '@/views/SalesReporting2/PenetrationReportByProgramContent/index.vue'
            ),
    },
];
