/*
 * @Author: zhengda.lv
 * @Date: 2021-10-18 11:12:18
 * @LastEditTime: 2021-11-02 11:45:23
 * @LastEditors: zhengda.lv
 * @Description:
 */
import { fetchGet, fetchPost, fetchPostPic } from '../index';
import { PgapiProgramProps } from '@/API/program';
// categoryList数据接口
export const categoryList = (): Promise<any> => {
    return fetchGet('/claimapi/parameter/category/list');
};
// 根据 program 查询 Period
export const getParameterPeriod = (
    params = { progDesignId: '' }
): Promise<any> => {
    return fetchGet('/claimapi/parameter/setup/getParameterPeriod', params);
};
// 根据 program 查询 ParameterSetup
export const getParameterSetup = (
    params = { progDesignId: '' }
): Promise<any> => {
    return fetchGet('/claimapi/parameter/setup/getParameterSetup', { params });
};
// 保存 Parameter Setup
export const parameterSave = (params = {}): Promise<any> => {
    return fetchPostPic('/claimapi/parameter/setup/save', params);
};
// 保存 Parameter Setup
export const parameterSave2 = (params = {}): Promise<any> => {
    return fetchPostPic(
        '/pgapi/program/data/claim/store_claim_parameter',
        params
    );
};
// 根据id获取ExtendInfo
export const getExtendInfo = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/parameter/extendInfo/getExtendInfo', params);
};
// 获取扩展信息 ExtendInfo列表
export const getExtendInfoList = (): Promise<any> => {
    return fetchGet('/claimapi/parameter/extendInfo/list');
};
// 获取category Field列表
export const categoryMappingList = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/parameter/categoryMapping/list', params);
};
// 获取parameter页面数据
export const getParameterData = (proId: any) => {
    return fetchGet(
        `/pgapi/program/data/claim/get_program_info?programId=${proId}`
    );
};

export const claimprogramProgramId = (params: {}): Promise<PgapiProgramProps> => {
    return fetchGet('/pgapi/program/data/load_program', params);
};

// 根据传入参数查询对应submission deadline增加天数值
export const getAddDays = (params: {}): Promise<PgapiProgramProps> => {
    return fetchGet('/claimapi/parameter/setup/getAddDays', { params });
};
