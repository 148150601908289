export default {
  search: "Search",
  save: "Saved success!",
  user: {
    navTip: "System Setting",
    navRole: "Role",
    navUser: "User",
    name: "Account",
    fulnmm: "Full Name",
    userType: "Legal Entity",
    password: "Password",
    filterName: "Filtered by Name",
    filterpgName: "Filtered by User Group",
    filterRoleName: "Filtered by Role",
    filterType: "Please select",
    edit: "Edit",
    deactivate: "Deactivate",
    setting: "Setting",
    cnName: "Name(Cn)",
    enName: "Name(En)",
    type: "Legal Entity",
    description: "Description",
    operation: "Operation",
    menuName: "Menu&Action",
    API: "API",
    grg: "User Group",
    org: "Org",
    orgFul: "Organization",
    email: "Email",
    findUser: "Find an user",
    findRole: "Find a role",
    importUser: "Import Setting",
    pleaseInput: "Please input",
    inputNameEn: "Please input English name",
    inputNameCn: "Please input Chinese name",
    inputRoleCode: "Please input role code",
    inputDescription: "Please input description",
    inputName: "Please input name",
    inputFullName: "Please input full name",
    inputEmailName: "Please input email",
    pgName: "User Group Name",
    delConfirm: "Are you sure to change the status of user group?",
    roleName: "Role Name",
    roleCode: "Role Code",
    roleType: "Legal Entity",
    names: "Name",
    code: "Code",
    approvalChianSetting: "Approval Chain Setting",
    programApprovalChain: "Program Approval Chain",
    payoutApprovalChain: "Payout Approval Chain",
    configuration: "Configuration",
    sampleCheckRule: "Sample Check Rule",
    entity: "Entity",
    NGMSRPOverride: "NST Group MSRP Override",
    IMPACTMSRP: "IMPACT MSRP Override",
  },
  program: {
    addTable: "Add new table",
    addRow: "Add new row",
    delete: "Delete",
    upload: "Upload",
    download: "Download",
    refresh: "Refresh",
  },
  retailValidation: {
    rv: "Retail Validation",
    dealer: " Dealer Overview",
    controlling: "Controlling Overview",
    sicco: "SICCO Overview",
    sipo: "Retail Information Overview",
  },
  placeholder: "Please Input",
  retailClaim: {
    dealer: "Dealer Claim",
    claim: "Sales Incentive Claim",
    RT: "F&L Retail Claim",
    RTClaimDetail: "F&L Claim Detail",
    WS: "AFC WS Claim",
    WSClaimDetail: "AFC WS Claim Detail",
    query: "Claim Enquiry",
    VINBased: "VIN Based Enquiry",
  },
  checking: {
    plan: "Checking Plan",
    checking: "Checking",
    sourceDataManagement: "Source Data Management",
    AG: "AG Management",
    TA: "Sign TA Management",
    TDTA: "TDTA Management",
    EnquiryProgram: "Enquiry Program",
    sicco: "SICCO Checking",
    spotChecking: "Spot Checking",
    spotCheckingControlling: "Spot checking rewiew & confirm",
    spotCheckingPendingApproval: "Pending Approval",
    approvalReview: "Approval Review",
    programDetail: "Program Detail",
    vinDetail: "VIN Detail",
    spotCheckOverview: "Spot Check Overview",
    spotCheckConfirm: "Spot Check Confirm",
    allClaimsReview: "All Claims Review",
    payoutPreparation: "Payout Preparation",
    dealer: "Dealer Communication",
    sipo: "Incentive Checking Communication",
  },
  payment: {
    payment: "Payment", //payment
    paymentMBAFC: "F&L Payment Management - RT",
    paymentApprove: "Mgmt. Payment Approve",
    paymentBuManagement: "BU payment Management",
    entitySiPayout: "Entity SI Payout Application",
    buSiPayout: "SI Payout Application",
    paymentDealerNo: "Delear No. Overview",
    paymentBuApprove: "BU Payment Management",
    createPayoutCode: "Create payout code",
    paymentSalesManagement: "Sales Payment Management",
    dcnTemplateSet: "DCN Template Set",
    vendorCode: "Vendor Code Overview",
    upload: "Upload",
    vehicleList: "Vehicle List",
    paymentDealerStatus: "Dealer Readiness Setting",
    paymentBBACSales: "BBAC Payment Management",
    voucherAttachment: "Voucher Attachment Set",
    DgrcAccounting: "DGRC Accounting",
    Breakdown: "Dealer Breakdown",
    BreakdownDetail: "经销商付款准备文件明细",
    dealerBreakdownReport: "Dealer Breakdown Report",
    paymentTracking: "Payment Tracking",
  },
  closing: {
    home: "Monthly Closing",
    configuration: "Configuration",
    setPMT: "Set PMT Closing Rule",
  },
  forecast: {
    home: "Monthly Forecast",
    configuration: "Configuration",
    setPMT: "Set PMT Forecast Rule",
  },
  vehiclelicense: {
    homepage: "新车零售行驶证上传",
  },
  taskCenter: {
    systemSetting: "System Setting",
    taskCenter: "Task Center",
    taskJob: "Task Job",
    packageTask: "Package Task",
  },
  planning: {
    planning: "Planning",
    homepage: "Homepage",
  },
  delay: {
    claimDelayRule: "Claim Delay Rule",
    dealerDelayClaim: "Dealer Delay Claim",
    sipoApprove: "SIPO Approve",
    regionApprove: "Region Approve",
  },
  communication: {
    communication: "沟通",
    appeal: "销售激励项目审核结果沟通",
    history: "查询历史沟通记录",
  },
  salesmeasure: {
    detail: "Program Detail",
    batchname: "Batch Name",
    releasedate: "Release Date",
    programcode: "Program Code",
    programnamecn: "Program Name(CN)",
    programnameen: "Program Name(EN)",
    programname: "Program Name",
    reset: "Reset",
    search: "Search",
    download: "Download",
    selectprogram: "Select Download Program",
    selectattch: "Select Download Attachment",
    salesmeasurelist: "Sales Measure Summary",
    smlfilename: "File Name",
    period: "Eligible Period/Submission Period",
    attachmentname: "Attachment Name",
    pleaseSelect: "Please Select!",
    bu: "BU",
    expand: "Expand",
    releaseperiod: "Release Period",
    collapse: "Collapse",
    programcount: "Program Count",
  },
  dealerData: {
    dealerCode: "Dealer Code",
    dealerNameEn: "DealerName(EN)",
    dealerNameCn: "DealerName(CN)",
    search: "Search",
    reset: "Reset",
    status: "Status",
    gsCode: "GS Code",
    dealerGroup: "Dealer Group",
    provinceEn: "Province(EN)",
    cityEn: "City(EN)",
    operation: "Operation",
    edit: "Edit",
    view: "View",
  },
  dealerDataEdit: {
    dealerCode: "Dealer Code",
    dealerNameEn: "DealerName(EN)",
    dealerNameCn: "DealerName(CN)",
    search: "Search",
    reset: "Reset",
    status: "Status",
    gsCode: "GS Code",
    dealerGroup: "Dealer Group",
    provinceEn: "Province(EN)",
    cityEn: "City(EN)",
    operation: "Operation",
    edit: "Edit",
    view: "View",
    active: "Active",
    save: "Save",
    baseInfo: "Base Info",
    legalNameCn: "LegalNameCN",
    legalNameEn: "LegalNameEN",
    additionalNameCn: "AddtionalName_CN",
    additionalNameEn: "AddtionalName_EN",
    provinceCn: "ProvinceCN",
    provinceEn2: "ProvinceEN",
    cityCn: "CityCN",
    cityEn2: "CityEN",
    addressCn: "AddressCN",
    addressEn: "AddressEN",
    zipCode: "ZIPCode",
    format: "Format",
    contactEmail: "ContactEmail",
    fixPhone: "FixPhone",
    dealerGroup2: "DealerGroup",
    swtCode: "SWTCode",
    vendorCode: "VendorCode",
    mbafcCode: "MBAFCCode",
    languageValue: "LanguageValue",
    dosCode: "DOSCode",
    isGovernmentRelated: "IsGovernmentRelated",
    salesInfo: "Sales Info",
    dealerType: "Dealer Type",
    yes: "Yes",
    no: "No",
    businessUnit: "Business Unit",
    changeHistory: "Change History",
    changeDate: "Change Date",
    changeBefore: "Change Before",
    changeAfter: "Change After",
    operationUser: "Operation User",
  },
  dealerVINBasedEnquiry: {
    searchConditions: "Search Conditions",
    vinNO: "VIN",
    vehicleClassification: "Type Class",
    vehicleCode: "Meterial No",
    vehicleName: "Model Name",
    modelYear: "Model Year",
    wholesaleSource: "Wholesale Source",
    wholesaleDate: "Wholesale Date",
    retailDate: "Retail Date",
    dealerCode: "Dealer Code",
    dealerNameCN: "Dealer Name（CN）",
    dealerNameEN: "Dealer Name（EN）",
    pleaseInput: "Please input...",
    pleaseSelect: "Please select",
    exportVehicleCompatibility: "Export Vehicle Compatibility",
    compatible: "Compatible",
    incompatible: "Incompatible",
    beginTime: "Begin Time",
    endTime: "End Time",
    search: "Search",
    reset: "Reset",
  },
  claimEnquiry: {
    dealerCode: "Dealer Code",
    dealerSwtCode: "SWT Code",
    vehicleName: "Model Name",
    vinNO: "VIN",
    projectPeriod: "Program Period",
    dealerNameCN: "Dealer Name（CN）",
    modelYear: "Model Year",
    appealStatus: "Appeal Status",
    projectNO: "Program Code",
    projectType: "Program Category",
    optionName: "Option Name",
    paymentRound: "Payout Round",
    checkResults: "Check Result",
    estimatedAmountOfCustomerPolicy: "Estimated Amount Of Customer Policy",
    actualAmount: "Final Bonus",
    createTime: "Create Time",
    status: "Status",
    dealerCommunicationDeadline: "Dealer communication Deadline",
    exemptionDeadline: "Exemption Deadline",
    dealerAcceptedTime: "Dealer Accepted Time",
    operation: "Operation",
    search: "Search",
    reset: "Reset",
    export: "Export",
    cannotWithdraw: "Cannot Withdraw",
    ok: "Ok",
    cancel: "Cancel",
    withdrawSuccessful: "Withdraw Sucessful",
    close: "Close",
    applyDeferredWithdraw: "Apply Deferred Withdraw",
    retailDateStr: "Retail Date",
    programPeriod: "Program Period",
    bu: "BU",
    region: "Region",
    automaticDeduction: "Automatic Deduction",
  },
  retailValidationSipo: {
    searchConditions: "Search Conditions",
    exportQueryResult: "Export Query Result",
    search: "Search",
    reset: "Reset",
    more: "More",
    vin: "VIN",
    model: "Model",
    brand: "Brand",
    dealerNameCN: "Dealer Name(CN)",
    dealerCodeCofico: "Dealer Code(Cofico)",
    dealerCodeSWT: "Dealer Code(SWT)",
    region: "Region",
    customerName: "Customer Name",
    retailType: "Retail Type",
    retailDate: "Retail Date",
    specialUsage: "Special Usage",
    submissionStatus: "Submission Status",
    eligibilityStatus: "Eligibility Status",
    failureReason: "Failure Reason",
    file: "File",
    operation: "Operation",
    vehicleSort: "Vehicle Sort",
    commissionNo: "Commission No",
    typeClass: "Type Class",
    modelYear: "Model Year",
    entity: "Entity",
    powertrain: "Powertrain",
    dealerSort: "Dealer Sort",
    dealerNameEN: "Dealer Name(EN)",
    dateSort: "Date Sort",
    invoiceDate: "Invoice Date",
    registrationDate: "Registration Date",
    checkResultSort: "Check Result Sort",
    collapse: "Collapse",
    vinBatchEnquiry: "VIN Batch Enquiry",
    vinList: "VIN List",
    commissionNoBatchEnquiry: "Commission No Batch Enquiry",
    commissionNoList: "Commission No List",
    clearAll: "Clear All",
    confirm: "Confirm",
    failureReasonDetails: "Failure Reason Details",
    feedbackTime: "Feedback Time",
    view: "View",
    invoice: "IN",
    license: "RE",
    details: "Details",
    retailDataDetails: "Retail Data Details",
    basicInformtion: "Basic Informtion",
    dealerInformtion: "Dealer Informtion",
    vehicleRetailHistory: "Vehicle Retail History",
    operationRecord: "Operation Record",
    retailStatus: "Retail Status",
    vehicleUsage: "Vehicle Usage",
    role: "Role",
    action: "Action",
    operationTime: "Operation Time",
    pleaseInput: "Please input",
    beginTime: "Begin Time",
    endTime: "End Time",
  },
  ssscOrder: {
    brand: "Brand",
    updatedTime: "Updated Time",
    fin: "FIN",
    vin: "VIN",
    programCode: "Program Code",
    packageValidFrom: "Package Valid From",
    typeClass: "Type Class",
    status: "Status",
    description: "Description",
    result: "Result",
    errorMessage: "Error message",
    startSystemClaimDate: "Start system claim date",
    claimID: "Claim ID",
    createdTime: "Created Time",
    activateDate: "Activate Date",
    packageName: "Package Name",
    dealerCode: "Dealer Code",
    outletId: "Outlet Id",
    dealerName: "Dealer Name",
    productCode: "Product Code",
    wholesalePrice: "Wholesale Price",
    iconContractNumber: "Icon Contract Number",
    reset: "Reset",
    search: "Search",
    export: "Export",
    cancel: "Cancel",
    confirm: "Confirm",
    programCodeMapping: "Program Code Mapping",
    startSystemClaim: "Start System Claim",
    pleaseInput: "Please input...",
    pleaseSelect: "Please select",
    beginTime: "Start date",
    endTime: "End date",
  },
  dealerQueryDetail: {
    siProgram: "SI Program",
    programCode: "Programe Code",
    programName: "Program Name",
    programCategory: "Program Categroy",
    retail: "Retail",
    vin: "VIN",
    model: "Model",
    modelYear: "Model Year",
    invoiceDate: "Invoice Date",
    registrationDate: "Registration Date",
    customerName: "Customer Name",
    retailType: "Retail Type",
    eligibility: "Eligibility",
    invoice: "Invoice",
    vehicleLicense: "Vehicle License",
    claimDetail: "Claim Detail",
    checkResult: "Check Result",
    amount: "Amount",
    comment: "Comment",
    dealerCommunication: "Dealer Communication",
    dealerCommunicationDeadline: "Dealer Communication Deadline",
    roundClosing: "Round Closing",
    lastCommunication: "Last Communication",
    document: "Document",
    type: "Type",
    applicationDocument: "Application Document",
    appeal1: "Appeal 1",
    appeal2: "Appeal 2",
    operationRecord: "Operation Record",
    action: "Action",
    operationUser: "Operation User",
    operationTime: "Operation Time",
  },
};
